.menuItem {
    padding: 8px;
     margin: 0px; 
    border: none;
    cursor: pointer;
}

.react-contextmenu {
margin: 0px;
 padding: 0px;
 
}
