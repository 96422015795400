.modal {
    &.chatWindowModal {
        z-index: 12;
        left: 0px;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0 !important;
        position: absolute;
        background: $white;
        display: none;

        .modal-dialog {
            max-width: 100%;
            width: 100%;
            height: 100%;
            margin: 0;
            padding-left: 0;

            .modal-content {
                border: 0;
                border-radius: 0;
                height: 100%;

                .close {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    font-size: 30px;
                    height: 50px;
                    width: 50px;
                    cursor: pointer;
                    z-index: 1;
                }

                .modal-body {
                    padding: 0;
                    height: 100%;
                    display: flex;

                    >div {
                        min-height: 100%;
                    }
                }
            }
        }

        &.show {
            display: block !important;
        }
    }

    &.minimizeChat {

        left: auto;
        width: 350px;
        height: 450px;
        bottom: 0;
        top: auto;
        z-index: 10;
        box-shadow: 0 0 20px rgba($black, .2);
        display: block;

        @media screen and (max-width: $screen-size-md) {
            width: 330px !important;
        }

        .chat-area-header {
            min-height: 50px;
            max-height: 50px;
            padding-right: 10px;
            background: $blue-cream;
            color: $white;
        }

        .chat-area-main {
            .chat-msg-profile {
                flex: 0 0 40px;

                svg {
                    height: 40px;
                    width: 40px;
                    min-height: 40px;
                    min-width: 40px;
                }
            }

            .chat-msg-content {
                max-width: 100%;
            }
        }

        .chat-msg-date {
            display: flex;

            span {
                margin-right: 3px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .chat-area-title {
            font-size: 16px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .chat-control-area {
            padding: 5px;
        }

        .chat-control-area .chat-send-btn {
            margin-left: 5px;
        }

        .sub-leftbar,
        .chat-area-title p {
            display: none;
        }

        .chat-area-header {
            .chat-area-group {
                display: none;
            }
        }

        .chat-area-actions>div {
            margin-left: 2px;
            border: 0;
            background: transparent;
            color: $white;

            &.add-chat-user,
            &.chat-min-btn {
                display: none;
            }

            &.chat-mxn-btn,
            &.chat-call-btn {
                display: inline-flex;
            }
        }

        // .chat-area-main .chat-msg{
        //     &:nth-last-child(2) {
        //         padding-bottom: 0;
        //     }
        // }

        .chat-area-main {
            padding-bottom: 20px;
        }

    }

    &.onminitoggle {
        height: 50px;

        .one-chat-min {
            .fa-chevron-down:before {
                content: "\f077";
            }
        }

        .chat-area-main,
        .chat-area-footer {
            display: none;
        }
    }
}

.sub-leftbar {
    height: 100%;
    flex: 0 0 225px;
    max-width: 225px;
    background-color: $light;
    left: 100%;
    top: 0;
    color: $black;
    padding: 20px;
    padding-right: 25px;
    overflow-y: auto;

    .user-card-list.user-cabin {
        .card {
            margin-bottom: 20px;
            max-width: calc(100% - 2px);

            svg {
                margin-top: 10px;
            }
        }
    }
}

// .modal-open {
// 	.left-sidebar {
// 		.expanded-link {
// 			display: none;
// 		}
// 	}
// }

// CHAT APP DESIGN
.chatBox {
    width: 100%;
    display: flex;
    flex-grow: 1 !important;
}


.chat-area {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    &-header {
        display: flex;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        flex-grow: 1;
        background: $white;
        // box-shadow: 0 0 10px rgba($dark, .2);
        border-bottom: 1px solid $border-color;
        min-height: 50px;
        max-height: 50px;
    }

    &-profile,
    svg {
        width: 32px;
        height: 32px;
        min-height: 32px;
        min-width: 32px;
        border-radius: 50%;
        object-fit: cover;
        background: $white;
    }

    &-footer {
        border-top: 1px solid $border-color;
        // box-shadow: 0 0 10px rgba($dark, .2);
    }

    &-title {
        font-size: 18px;
        text-transform: capitalize;
        font-weight: 600;

        p {
            font-size: 14px;
            font-weight: normal;
            color: $gray-600;
        }
    }

    &-main {
        flex-grow: 1;
        overflow-y: auto;

        .chat-msg-profile {
            flex: 0 0 40px;
        }

        .chat-msg-content {
            max-width: 100%;
        }
    }
}


.chat-area-group {
    flex-shrink: 0;
    display: flex;

    * {
        border-left: 1px solid $border-color;
    }

    *+* {
        margin-left: -5px;
    }

    span {
        width: 32px;
        height: 32px;
        background-color: $blue-cream;
        color: $white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
    }
}

.chat-area-actions {
    display: flex;
    align-items: center;
    margin-left: 20px;

    >div {
        border-radius: 50%;
        height: 32px;
        width: 32px;
        min-height: 32px;
        min-width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $blue-cream;
        color: $white;
        margin-left: 7px;
        cursor: pointer;
    }

    .add-chat-user {
        background: $white;
        color: $blue-cream;
    }

    .chat-info-btn {
        background: $b-color;
        color: $dark;

        &.chat-closed {
            cursor: pointer;
            border: 0;
            background: $white;
            color: $gray-500;
        }

        &.chat-mxn-btn {
            display: none;
        }

    }

}


.chat-area-main {

    padding: 40px 20px 40px;

    .chat-area-group {
        justify-content: flex-end;

        .chat-area-profile,
        svg {
            height: 20px;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            font-size: 10px;
            background: $white;
            margin-left: -2px;
        }

        span {
            background: $blue;
            height: 21px;
            width: 21px;
            min-height: 21px;
            min-width: 21px;
            font-size: 10px;
        }
    }

    .chat-msg {
        display: flex;
        padding-bottom: 45px;

        &-content {
            margin-left: 12px;
            max-width: 70%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-text {
            background-color: $border-color;
            padding: 10px 20px;
            margin-top: 5px;
            border-radius: 0 20px 20px 20px;
            line-height: 1.5;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            word-break: break-word;

            &+& {
                margin-top: 10px;
            }
        }
    }


    .chat-msg-img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        object-fit: cover;
    }

    .chat-msg-profile {
        flex-shrink: 0;
        margin-top: -20px;
        margin-bottom: auto;
        position: relative;

        svg {
            height: 40px;
            width: 40px;
            min-height: 40px;
            min-width: 40px;
            border-radius: 50%;
            border: 2px solid $border-color;
            background: $white;
        }

    }

    .chat-msg-date {
        position: absolute;
        left: calc(100% + 12px);
        top: 0;
        font-size: 12px;
        color: $dark;
        white-space: nowrap;
        color: $gray-600;

        span {
            color: $dark;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .owner {
        flex-direction: row-reverse;

        .chat-msg-content {
            margin-left: 0;
            margin-right: 12px;
            align-items: flex-end;
        }

        .chat-msg-text {
            background-color: $blue-cream;
            color: $white;
            border-radius: 20px 0 20px 20px;

            a {
                color: #b6d1ff;
            }

        }

        .chat-msg-date {
            left: auto;
            right: calc(100% + 12px);
        }
    }

    .chat-msg-text img {
        max-width: 300px;
        width: 100%;
    }




    .chat-msg .text.typing {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        padding: 0 10px;

        .wave .dot {
            display: inline-block;
            width: 6px;
            height: 6px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            -o-border-radius: 100%;
            border-radius: 100%;
            margin-right: 1px;
            -webkit-animation: wave 1.3s linear infinite;
            -moz-animation: wave 1.3s linear infinite;
            -ms-animation: wave 1.3s linear infinite;
            -o-animation: wave 1.3s linear infinite;
            animation: wave 1.3s linear infinite;
            background: $gray-600;
        }

        .wave .dot:last-of-type {
            margin-right: 0;
        }

        .wave .dot:nth-child(2) {
            -webkit-animation-delay: -1.1s;
            -moz-animation-delay: -1.1s;
            -ms-animation-delay: -1.1s;
            -o-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        .wave .dot:nth-child(3) {
            -webkit-animation-delay: -.9s;
            -moz-animation-delay: -.9s;
            -ms-animation-delay: -.9s;
            -o-animation-delay: -.9s;
            animation-delay: -.9s;
        }

        @keyframes wave {

            0%,
            100%,
            60% {
                transform: initial
            }

            30% {
                transform: translateY(-5px)
            }
        }
    }
}


.chat-control-area {
    padding: 15px;
    display: flex;
    align-items: center;
    height: 100%;

    textarea {
        resize: none;
        width: 100%;
        background: $light;
        border: 0;
        padding: 13px;
        border-radius: 0;
        padding-right: 30px;
    }

    .inout-group {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
    }

    .chat-send-btn {
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: $blue-cream;
        color: $white;
        font-size: 18px;
        border-radius: 0;
        margin-left: 15px;
        outline: none;
    }

    .chat-emoji {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 5px;
        height: 100%;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        color: $blue-cream;
        font-size: 18px;
        border-radius: 50%;
        z-index: 1;
    }

}

// ONE TO ONE CHAT DESIGN

.oneChatwindow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 12;

    .chatWindowModal {
        position: initial;
        margin-left: 15px;
    }

    >span {
        display: flex;
    }

    >.d-flex {
        align-items: flex-end;
    }
}

.modal.support-chat {
    right: 90px;
    position: fixed;
    z-index: 9999;

    .chat-area-header {
        background: $green;
    }

    .chat-control-area {
        .chat-send-btn {
            background: $green;
        }

        .chat-emoji {
            color: $green;
        }
    }

    .chat-area-main {
        &::-webkit-scrollbar-thumb {
            background-color: $green;
        }
    }
}


.main-panel .react-draggable {
    z-index: 999;
}

.react-draggable {
    z-index: 9999;
}



.joinZoom {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    border: 0;
    border-radius: 0;

    .modal-header,
    .modal-body,
    .modal-content {
        border-radius: 0;
    }

    .modal-body,
    .modal-content {
        border: 0;
    }

    .zoom-frame {
        display: flex;
        height: 100%;
        color: $white !important;
    }

    .modal-content {
        height: 100%;
        position: relative;

        .modal-header {
            position: absolute;
            top: 3px;
            left: auto;
            right: -10px;
            z-index: 1;
            border: 0;
            padding: 0;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;

            .close {
                margin: auto;
                opacity: 1;
                color: $white;
                font-size: 20px;
            }
        }

        .modal-body {
            padding: 0;
        }
    }
}

.chat-msg-profile {
    position: relative;
    border-radius: 50%;

    &.in-active {
        &::after {
            content: "\f057";
            font-family: 'Font Awesome 5 Free';
            font-weight: bold;
            position: absolute;
            right: -2px;
            bottom: -2px;
            color: #888;
        }
    }
}

.emoji-mart {
    max-width: 100%;
    margin: auto;
    .emoji-mart-search {
        /* top: 4px; */
        right: 0;
        max-width: 100%;
        margin: 10px auto;
    }

    svg {
        width: 17px;
        height: 17px;
        min-height: 17px;
        min-width: 17px;
        border-radius: 50%;
    }
}