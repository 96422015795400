.left-sidebar {
    width: 225px;
    max-width: 225px;
    background-color: $blue-cream;
    background-image: linear-gradient(315deg, $blue-cream 0%, $blue 74%);
    height: 100%;
    color: $white;
    transition: all .15s ease-in-out;
    position: absolute;
    left: -225px;
    top: 0;
    height: 100%;
    z-index: 101;


    .user-list-panel {
        height: calc(100% - 221px);
        overflow: auto;
        background: rgba($white, .15);

        ul {
            li {
                >.btn {
                    padding: 10px 20px 10px 20px;
                    justify-content: start;
                    color: $white;
                    background: none;
                    font-weight: 600;
                    border-left: 3px solid transparent;
                    // border-bottom: 1px solid rgba($white, .15);
                    border-radius: 0;

                    &::after {
                        content: "\f067";
                        color: $white;
                        font-family: 'Font Awesome 5 Free';
                        // border: 2px solid $white;
                        font-size: 10px;
                        width: 20px;
                        min-width: 20px;
                        border-radius: 50%;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 0;
                        margin-left: auto;
                        transition: all .15s ease-in-out;
                    }

                    &:hover:after {
                        color: $white;
                        border-color: $white;
                    }

                    &[aria-expanded="true"],
                    &:hover {
                        color: $white;
                        background: rgba($black, .20);
                        border-color: $light-blue;
                    }

                    &[aria-expanded="true"]::after {
                        content: "\f068";
                        color: $white;
                        border-color: $white;
                        transition: all .15s ease-in-out;
                    }
                }

                .user-list {
                    padding: 0;

                    li {
                        margin: 2px 0;
                        position: relative;

                        .msg-status {
                            position: absolute !important;
                            right: 10px;
                            font-size: 11px;
                            height: 100% !important;
                            display: flex;
                            // flex-direction: column;
                            justify-content: flex-end;
                            width: auto;
                            align-items: center;

                            >div {
                                position: initial !important;
                                height: auto !important;
                            }
                        }


                        .msg-time {
                            position: initial !important;
                            height: auto !important;
                            font-size: 11px !important;
                            width: auto !important;
                            margin: auto 0;
                            z-index: 10;

                            +div {

                                position: initial !important;
                                height: auto !important;
                                width: auto !important;
                                margin-bottom: 5px;
                                margin-left: auto;

                                span {
                                    position: initial !important;
                                    padding: 5px !important;
                                    font-size: 10px !important;
                                }

                                &:empty {
                                    display: none;
                                }
                            }
                        }

                        a {
                            img {
                                margin-right: 5px;
                            }

                            color: $white;
                            font-size: 13px;
                            padding-right: 45px;
                        }
                    }
                }

            }
        }

        .user-list {
            li {
                padding-left: 0;


                &.current {
                    .msg-time {
                        color: $blue;
                    }

                    a {
                        background: $light;
                        color: $blue;
                        transition: none;
                        font-weight: 400;

                        &::before {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: -20px;
                            height: 20px;
                            width: 20px;
                            background-size: 100%;
                            border-radius: 0;
                            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52'%3E%3Cpath data-name='Path 143' d='M259.51 259.52c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828a237.651 237.651 0 00-7.292-8.589c-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3A115.051 115.051 0 000 .081h259.51z' fill='%23f1f5f8'/%3E%3C/svg%3E") !important;
                            transform: rotate(90deg);
                            transition: none;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            bottom: -20px;
                            height: 20px;
                            width: 20px;
                            background-size: 100%;
                            border-radius: 0;
                            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52'%3E%3Cpath data-name='Path 143' d='M259.51 259.52c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828a237.651 237.651 0 00-7.292-8.589c-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3A115.051 115.051 0 000 .081h259.51z' fill='%23f1f5f8'/%3E%3C/svg%3E") !important;
                            transform: rotate(0deg);
                            transition: none;
                        }
                    }

                }

                a {
                    border-radius: 0;
                    border: 0;
                    position: relative;
                    text-transform: capitalize;
                    transition: none;



                    &:hover {
                        border-radius: 0;
                    }

                    &:focus {
                        // background: $light;
                        // color: $blue;     
                        border-radius: 0;
                        // &::after{
                        //     color: $blue;     
                        // }                   
                    }


                    &::after {
                        content: "\f105";
                        border: 0;
                        font-size: 14px;
                        display: none;
                    }
                }
            }
        }
    }

    .user-cabin {
        padding: 20px;
        min-height: 212px;

        .card {
            background: rgb(184, 227, 253);
            background: linear-gradient(120deg, rgba(184, 227, 253, 0.16850490196078427) 47%, rgba(255, 255, 255, 0.2) 47%);
            border-color: rgba($white, .5);
            box-shadow: 0 0 10px rgba($white, .5) inset;
            color: $white;
            width: 100%;
            max-width: calc(100% - 20px);
            margin: auto;

            .card-img {
                margin-top: auto;
            }

            .name-time,
            .status {
                background: rgba($white, .3);
                cursor: pointer;
            }

            .time-zone {
                color: $white;
                padding-top: 5px;

                img,
                &::before {
                    filter: brightness(200%);
                }
            }


            &::before {
                background: rgba($white, .9);

                @media screen and (max-width: $screen-size-md) {
                    height: 30px;
                }
            }

            &.card-door-open {
                background: rgb(184, 227, 253);
                background: linear-gradient(120deg, rgba(184, 227, 253, 0.16850490196078427) 47%, rgba(255, 255, 255, 0.2) 47%);
                border-color: rgba($white, .5);
                box-shadow: 0 0 10px rgba($white, .5) inset;

                .name-time,
                .status {
                    background: rgba($white, .3);
                    cursor: pointer;
                }

                &::before {
                    background: url("#{$bgimage-path}personal-user-card-door.png") no-repeat;
                }
            }

        }
    }

}

// LEFT PANEL CUSTOM SCROLL

// .user-list-panel::-webkit-scrollbar {
//     scrollbar-width: thin;
//     scrollbar-color: $white $blue;
// }

// .user-list-panel::-webkit-scrollbar {
//     width: 10px;
// }

// .user-list-panel::-webkit-scrollbar-track {
//     background: transparent;
// }

// .user-list-panel::-webkit-scrollbar-thumb {
//     background-color: $white;
//     border-radius: 20px;
//     border: 3px solid $blue;
// }






.overlay-loader {
    z-index: 9999;
}



.expanded-link {
    position: absolute;
    left: 0;
    top: 0;
    color: $blue;
    border: 0;
    height: 100%;
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    z-index: 9;
    border-left: 1px solid transparent;
    transition: all .15s ease-in-out;

    &:hover {
        color: $blue;
        background-color: $white;
        box-shadow: 0 0 10px rgba($black, .1);
    }

    &:focus {
        color: $blue;
        background-color: transparent;
        box-shadow: none;
    }

}


.toggle {
    .left-sidebar {
        left: 0;
        transition: all .15s ease-in-out;
    }

    .expanded-link {

        // border-color: $blue;
        &::before {
            content: "\f104";
        }
    }
}