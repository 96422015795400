/*!
 * Bootstrap v4.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import "bootstrap-custom/functions";
 @import "bootstrap-custom/variables"; 
 
 @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300;1,900&display=swap');
 
 @import "../../../node_modules/bootstrap/scss/functions";
 @import "../../../node_modules/bootstrap/scss/variables";
 @import "../../../node_modules/bootstrap/scss/mixins";
 @import "../../../node_modules/bootstrap/scss/root";
 @import "../../../node_modules/bootstrap/scss/reboot";
 @import "../../../node_modules/bootstrap/scss/type";
 @import "../../../node_modules/bootstrap/scss/images";
 @import "../../../node_modules/bootstrap/scss/code";
 @import "../../../node_modules/bootstrap/scss/grid";
 @import "../../../node_modules/bootstrap/scss/tables";
 @import "../../../node_modules/bootstrap/scss/forms";
 @import "../../../node_modules/bootstrap/scss/buttons";
 @import "../../../node_modules/bootstrap/scss/transitions";
 @import "../../../node_modules/bootstrap/scss/dropdown";
 @import "../../../node_modules/bootstrap/scss/button-group";
 @import "../../../node_modules/bootstrap/scss/input-group";
 @import "../../../node_modules/bootstrap/scss/custom-forms";
 @import "../../../node_modules/bootstrap/scss/nav";
 @import "../../../node_modules/bootstrap/scss/navbar";
 @import "../../../node_modules/bootstrap/scss/card";
 @import "../../../node_modules/bootstrap/scss/breadcrumb";
 @import "../../../node_modules/bootstrap/scss/pagination";
 @import "../../../node_modules/bootstrap/scss/badge";
 @import "../../../node_modules/bootstrap/scss/jumbotron";
 @import "../../../node_modules/bootstrap/scss/alert";
 @import "../../../node_modules/bootstrap/scss/progress";
 @import "../../../node_modules/bootstrap/scss/media";
 @import "../../../node_modules/bootstrap/scss/list-group";
 @import "../../../node_modules/bootstrap/scss/close";
 @import "../../../node_modules/bootstrap/scss/modal";
 @import "../../../node_modules/bootstrap/scss/tooltip";
 @import "../../../node_modules/bootstrap/scss/popover";
 @import "../../../node_modules/bootstrap/scss/carousel";
 @import "../../../node_modules/bootstrap/scss/utilities";
 @import "../../../node_modules/bootstrap/scss/print";
 @import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss"; 
 @import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss"; 
 @import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss"; 
 @import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss"; 
 @import "../../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims.scss"; 
 
 
 // Bootstrap Customization 
 @import "bootstrap-custom/mixins"; 
 @import "bootstrap-custom/buttons";
 
 // Main
 @import "bootstrap-custom/user-panel";
 
 @import "bootstrap-custom/common";
 @import "bootstrap-custom/model-popup";
 @import "bootstrap-custom/left-sidebar";
 @import "bootstrap-custom/main-component";
 @import "bootstrap-custom/right-sidebar";
 @import "bootstrap-custom/office-layout";
 @import "bootstrap-custom/chat-window";
 @import "bootstrap-custom/rcbCalender";
 @import "bootstrap-custom/custom";


 // RESPONSIVE 
 @import "bootstrap-custom/responsive";