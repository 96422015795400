.btn {
  position: relative;
  min-height: 32px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;

  &:focus,
  &.focus,
  &:hover {
    outline: 0;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    background-color: $primary;

    &:focus {
      box-shadow: none;
    }
  }

}

button {

  &:focus,
  &.focus,
  &:hover {
    outline: 0;
    box-shadow: none;
  }
}

button.btn-filter-icon {
  min-height: 38px;
  padding: 7px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  max-width: 300px;
}

.css-yk16xz-control+div {
  width: 100%;
}

.css-2b097c-container {
  z-index: 100;
}

.ReactTable .rt-noData{
  z-index: 0;
}