.model-right-bar {
    // position: relative;
    // width: 300px;
    // z-index: 0;

    position: absolute;
    // width: 300px;
    z-index: 1000;
    left: auto;
    right: 70px;
    height: 100%;
    box-shadow: -5px 0 10px rgba(0, 0, 0, .15);
    margin: 0;
    top: 0;
    min-width: 350px;

    .modal-dialog {
        position: relative;
        margin: auto;
        width: 100%;
        max-width: 100%;
        height: 100%;

        .modal-content {
            border: none;
            // border-left: 2px solid $border-color;
            height: 100%;
            border-radius: 0;
        }

        .modal-body {
            padding: 20px 15px;
        }

        .modal-header {
            min-height: 50px;
        }

    } 

}

.model-right-bar.rightbar_sm {
    max-width: 550px;
    width: 100%;
}

.modal-header {
    padding: 10px 15px;
    // min-height: 80px;
    display: flex;
    align-items: center;
    border-radius: 0;
    // border: 0;
    border-width: 1px;

    .modal-title {
        font-weight: 500;
        font-size: 16px;
    }

    .close {
        outline: none;
        font-size: 18px;
        color: $black;
        opacity: 1;
    }
}

.modal-backdrop {
    display: none;
}



// all main modal

.main-modal {
    background: rgba(0, 0, 0, .4);

    .modal-dialog {
        min-width: 1150px;

        @include media-breakpoint-down(lg) {
            min-width: 1000px;
        }
    }

    .modal-content {
        border: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, .5);
        overflow: hidden;
    }
}

.avtar-selection-content {
    padding: 0;
}

.main-tab {
    .nav-pills {
        border-bottom: 1px solid $border-color;
        padding: 0 5px;

        .nav-item {
            flex: auto;
        }

        .nav-link {
            color: $second-dark;
            font-size: 14px;
            position: relative;
            text-align: center;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 3px;
                width: 100%;
                background: $blue;
                display: none;
            }

            &.active {
                font-weight: bold;
                color: $blue;
                border-bottom-color: $blue;

                // &::after {
                //     display: block;
                // }
            }
        }
    }

    .tab-content {
        min-height: 450px;

        svg {
            margin: 5px auto 0;
            width: 100% !important;
        }

        .MuiSelect-icon {
            width: 20% !important;
            left: auto;
            right: 0;
            margin: 0;
        }

        .tab-pane {
            min-height: 440px;

            center {
                .btn {
                    margin-top: 150px !important;
                }
            }
        }

    }

    &.main-vertical-tab {

        .nav-pills {
            border: 0;
        }

        .nav-link {
            border-bottom: 1px solid $border-color;
            text-align: left;
            font-size: 14px;

            &::after {
                bottom: -1px;
            }
        }

        .tab-content {
            padding: 1.5rem 10px 0;
            height: 100%;
        }

    }

}

.avtar-tabpan {
    .card {
        padding: 20px 20px 0;
        text-transform: capitalize;
    }

    img,
    svg {
        margin: 5px auto 0;
        width: 100% !important;
    }
}

.select-avtar-div {
    max-height: 450px;
    overflow-y: auto;

    img {
        margin: auto;
        display: block;
    }
}


.task-add {
    color: $blue;
    // margin-bottom: 15px;
    font-size: 14px;

    >div {
        cursor: pointer;
    }
}


.jira-task-popup {
    max-width: 50%;


    .jira-list-id {
        display: flex;
        font-size: 18px;
        margin-bottom: 20px;
        flex-direction: column;

        img {
            margin-right: 7px;
        }

        .fa-close {
            margin-left: auto;
            cursor: pointer;
        }
    }

    .ReactTable {
        min-height: 600px;
    }

    .modal-body {

        >div {
            min-height: 100%;
        }

        >div,
        .jira-task-list {
            flex: auto;
            padding: 0;

            .form-control {
                min-height: 38px;
            }

            .css-yk16xz-control {
                border-radius: 0;
            }
        }

        .jira-task-desc {
            flex: 0 0 50%;
            border-left: 1px solid $border-color;
            padding: 0 15px;
            margin-left: 15px;
            // display: none;
            max-width: 50%;
            position: relative;
            transition: all .15s ease-in-out;

            .fa-close {
                position: absolute;
                right: 0;
                top: 0;
            }



            .comment-div {
                max-height: 150px;
                overflow: hidden;
                overflow-y: auto;
            }

        }

        .nav-pills .nav-link {
            border-radius: 0;
            border-bottom: 1px solid transparent;
            &.active{
                border-bottom-color: $blue;
            }
        }

    }


}

.to_do_modal {
    &.model-right-bar {
        width: 65%;

        .ReactTable {
            width: 100%;
        }

        .MuiAccordion-root {
            margin-bottom: 15px;
            background: $white;
            box-shadow: none;
            border: 1px solid rgba($blue, .25);
            border-left: 2px solid rgb(0, 200, 117);
            border-radius: 0 !important;

            &::before {
                display: none;
            }

            .MuiAccordionSummary-root {
                padding-left: 50px;
            }

            .MuiIconButton-root {
                margin: 0;
                position: absolute;
                left: 0;
            }

            .MuiSvgIcon-root {
                transform: rotate(-90deg);
            }

            &.Mui-expanded {
                .MuiIconButton-label {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

.ReactTable {

    .rt-tr-group,
    .rt-trs {
        flex: 0 0 auto !important;
    }
}

.ReactTable .rt-table {
    overflow: hidden;
}

.ReactTable .rt-thead,
.ReactTable .rt-tbody {
    min-width: auto !important;
}

.ReactTable .rt-thead {
    overflow-x: hidden;
    overflow-y: auto;
}

.ReactTable .rt-tbody {
    overflow-x: hidden !important;
}


.react-datepicker-popper {
    z-index: 10;
}

.rt-table {
    background-color: $white;
}

.custom-jira-action {

    select,
    input,
    textarea {
        min-width: 180px;
    }

    textarea {
        min-height: 35px;
        padding: 7px 10px;
    }
}

.model-left-bar {
    left: 0;
    right: auto;
    top: 0;
    box-shadow: 5px 0 10px rgba($black, .15);
}

.date-range-center {
    label {
        display: block;
    }
}


.ReactTable .rt-tr-group{
    align-items: unset !important;
}