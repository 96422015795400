body,
html,
.main-wrapper,
#root {
  height: 100%;
  width: 100%;
}

a {
  cursor: pointer;
}

.wrapper {
  height: 100%;
  width: 100%;
  max-width: 100%;
  // overflow: hidden;

  a:hover,
  a:focus {
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
  }


  .search-box {
    position: relative;

    .form-control {
      padding-right: 35px;
      min-width: 220px;
      border-radius: 0;
    }

    .form-control-feedback {
      position: absolute;
      right: 0;
      z-index: 2;
      width: 2.375rem;
      height: 100%;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: $second-dark;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


}

.form-control {
  height: auto;
  min-height: 32px;
}

.form-group {
  margin-bottom: 15px;
}


.tab-center-content {
  display: flex;
  min-height: 300px;
  align-items: center;

  >div {
    min-width: 500px;
    text-align: center;

    .form-control {
      font-size: 17px;
    }

  }

}

.sticky-header+div {
  z-index: 0;
}

.timings_todo {
  select {
    padding: 0px;

  }
}

.modal-body .react-datepicker-wrapper {
  width: 100%;
}



* {
  scrollbar-width: thin;
  scrollbar-color: $gray-400 transparent;
}

*::-webkit-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $gray-400 transparent;
}

*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $gray-400;
  border-radius: 0;
}
 
.whrlgig_slider::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  scrollbar-width: thin;
}

.whrlgig_slider::-webkit-scrollbar-track {  
  background-color: #eee;
}

.whrlgig_slider::-webkit-scrollbar-thumb { 
  background-color:  $gray-400; 
}

.whrlgig_slider::-webkit-scrollbar:vertical {
  display: none;
}