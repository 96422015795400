.dashboard-wrapper {
    display: flex;
    height: 100%;
    max-width: 100%;
    width: 100%;
    flex: 1;
    position: relative;
    // overflow: hidden;

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 225px;
        transition: all .15s ease-in-out;
        max-width: calc(100% - 70px);
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .content-head {
        min-height: 50px;
        display: flex;
        align-items: center;
        padding: 5px 30px 5px 20px;
        position: relative;
        z-index: 10;
        background: $white;
        border-bottom: 1px solid $border-color;

        .search-box {
            margin: 0;
            margin-left: 20px;
        }
    }

    .alert-reminder {
        min-height: 50px;
        border-top: 1px solid $border-color;
        display: flex;
        align-items: center;
        padding: 5px 15px;
        background: url("#{$bgimage-path}bg-pattern.png") repeat;

        i {
            font-size: 18px;
            color: $red;
            margin-right: 10px;
        }
    }



    .main-panel {
        // max-height: calc(100vh - 80px - 66px);
        height: calc(100vh - 50px);
        overflow-y: auto;
        padding: 0 15px;
        flex: 1;
        padding: 15px 10px;
        width: auto;
        position: relative;
        z-index: 1;

        // &::-webkit-scrollbar {
        //     scrollbar-width: thin;
        //     scrollbar-color: $blue $light;
        // }

        // &::-webkit-scrollbar {
        //     width: 10px;
        // }

        // &::-webkit-scrollbar-track {
        //     background: $light;
        // }

        // &::-webkit-scrollbar-thumb {
        //     background-color: $blue;
        //     border-radius: 20px;
        //     border: 3px solid $light;
        // }


        .welcone-panel {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            >div {

                strong {
                    font-size: 18px;
                }

                p {
                    margin-top: 10px;
                }

                width: 50%;
                text-align: center;
            }

        }

    }
}



// USER LIST PANEL

.user-list-content {
    .card {
        border: 2px solid $border-color;
        position: relative;
        padding: 15px;
        text-align: center;
        padding-top: 65px;
        min-height: 130px;
        margin-top: 50px;
        margin-bottom: 30px;
        transition: all 0.15s ease-in-out;
        cursor: pointer;

        img,
        svg {
            height: 100px;
            width: 100px;
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            top: -50px;
            border-radius: 50%;
            border: 2px solid $border-color;
        }

        strong {
            font-size: 16px;
        }

        p {
            font-size: 12px;
            color: $second-dark;
            margin: auto;
        }

        &:hover {
            box-shadow: 0 0 15px rgba($blue, .3);
        }

        &.active {
            border: 2px solid $green;
            box-shadow: 0 0 15px rgba($blue, .3);

            &::after {
                content: "\f058";
                color: #10b759;
                font-family: 'Font Awesome 5 Free';
                font-weight: bold;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                bottom: -10px;
                font-size: 20px;
                background: #fff;
                height: 20px;
                width: 20px;
                line-height: 20px;
                border-radius: 50%;
            }

            img {
                border: 2px solid $green;
            }
        }
    }
}



.term-condition {
    padding: 50px 0;

    img {
        width: auto;
        margin-top: 0;
        margin-bottom: 30px;
    }

    .card {
        padding: 50px;
    }

    p {
        margin-bottom: 5px;
        text-align: justify;
    }

    b {
        margin-top: 20px;
        display: inline-block;
    }

    .policy-link {
        margin: 50px 0;
    }
}


.trans-bg {
    background: rgba($black, .6);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.highlight-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;

    .h-body {
        padding: 15px;
        background: $white;
        box-shadow: 0 0 40px rgba($black, .3);
        border-radius: 5px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: 80px;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 13px solid $white;
        }

    }

    .h-content {
        padding: 15px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid $blue;

        img {
            display: block;
            margin: 0 auto 15px;
            max-width: 150px;
        }

        b {
            color: $blue;
        }

    }

    .h-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
            padding: 5px 15px;
            min-height: 35px;
            width: 100px;

            &::before,
            &::after {
                display: none !important;
            }
        }

    }

    &#highlight1 {
        font-size: 16px;
    }

    &#highlight2 {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        color: $dark;
        background: rgba($black, .6);
        display: none;

        .h-body {
            padding: 15px;
            background: $white;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            margin-top: calc(100% - 10px);
            margin-left: 30px;
            min-width: 280px;

            @media screen and (max-width: 1366px) {
                margin-top: calc(90% + 0px);
            }
        }
    }

    &#highlight3 {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        color: $dark;
        background: rgba($black, .6);
        display: none;

        .h-body {
            padding: 15px;
            background: $white;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            margin-top: calc(100% + 100px);
            margin-left: 20px;
            min-width: 340px;

            &::before {
                left: 65px;
            }

            @media screen and (max-width: 1366px) {
                margin-top: calc(100% + 90px);
            }

            .btn {
                background: $primary;
            }

        }
    }

    &#highlightImport {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        color: $dark;
        background: rgba($black, .6);
        display: none;

        .h-body {
            padding: 15px;
            background: $white;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            margin-top: calc(100% + 170px);
            margin-left: 20px;
            min-width: 340px;

            &::before {
                left: 65px;
            }

            @media screen and (max-width: 1366px) {
                margin-top: calc(100% + 130px);
            }

            .btn {
                background: $primary;
            }

        }
    }


    &#highlight4 {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        color: $dark;
        display: none;

        .h-body {
            width: 500px;
            margin: auto;
            margin-top: 17.5%;

            @media screen and (max-width: 1366px) {
               // margin-top: 28%;
            }

            .btn {
                background: $primary;
            }

            &::before {
                left: 0;
                right: 0;
                margin: auto;
            }

        }
    }


    &#highlight1 {

        .h-body {

            &::before {
                display: none;
            }

        }
    }


}

.main-highlight {
    z-index: 9999;
    position: relative;
}

.cabin-highlight {
    z-index: 9999;
    position: relative;
    background: $light;
}

.MuiMenuItem-root{
    font-size: 14px;
}

.topBarBox {
    display: flex;

    div {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
        color: $gray-600;
        text-transform: capitalize;

        i { 
            text-align: center; 
            margin-right: 7px;
        }

        &:hover {
            color: $blue;
        } 
    }
}