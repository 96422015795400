.rating-tbl th,
.rating-tbl td {
  border: 0;
  padding: 7px;
  text-align: center;
}

.rating-tbl th {
  border-bottom: 1px solid #888;
}



/*--------------------------------------------------------------------------------*/
/*-------------- DASHBOARD DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.list-user {
  font-size: 14px;
  font-weight: 700;
  color: white;
  margin-left: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.list-user:hover {
  color: rgb(185, 181, 181);
}

/* .modal-header {
    padding: 5px 25px;
    min-height: 0px;
  } */

.user-list-content .card {
  padding-top: 1px;
}

/* .left-sidebar .user-list-panel ul li .user-list {
  padding-top: 0px;
  padding-right: 5px;
  padding-bottom: 0px;
  padding-left: 25px;
} */

.nav-link {
  cursor: pointer;
}

.inputButton {
  display: inline-block;
  margin-top: 0;
}

/* .user-card-list .card:hover::after {
  content: "\f058";
  color: #10b759;
  font-family: 'Font Awesome 5 Free';
  font-weight: bold;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  font-size: 20px;
  background: #fff;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
}

.user-card-list .card:hover {
  border: 2px solid #10b759;
} */
.button-style {
  padding: 10px 20px 10px;
}

.button-style .button {
  border: 1px solid $white;
  color: $white;
  padding: 5px 8px;
  background-color: transparent;
  margin: 5px 0;
  width: 100%;
  border-radius: 4px;
  outline: none;
  display: block;
}

.button-style .slack-btn {
  padding: 3px;
  background-color: $white;
  text-align: center;

  img {
    max-width: 135px;
    margin: auto;
  }
}

.alignEnd {
  text-align: justify;
}

// .modal .model-right-bar {
//   display: none
// }

.todolist-panel .react-draggable {
  cursor: pointer;
}

.search-results {
  background-color: white;
  position: absolute;
  z-index: 4;
  min-width: 220px;
  overflow-x: hidden;
  // border-bottom-left-radius: 20px;
  // border-bottom-right-radius: 20px;
  border: 1px solid #e3e3e3;
  max-height: 350px;
  /* overflow-y:scroll ; */

}

.wrapper .search-box .search-results-bar {
  padding-right: 35px;
  min-width: 220px;
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  box-shadow: none;
}

.search-results .options {
  display: block;
  width: 100%;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #e3e3e3;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 3px 15px;

  &:last-child {
    border-bottom: 0;
  }
}

/* .search-results .options:first-child{
  border-radius: ;
} */

.search-results .options:hover {
  background-color: rgb(41, 123, 245);
  color: white;
}

.dashboard-wrapper .content-head .search-user {
  margin: 0;
  margin-left: 10px;
}

.search-box .search-user .form-control {
  display: inline-block;
  width: auto;
  box-shadow: none;
}

.dashboard-wrapper .content-head .search-user {
  margin: 0;
  margin-left: 10px;
}

.search-box .search-user .form-control {
  display: inline-block;
  width: auto;
}

/* .office-list{
  margin-top: 8px;
} */

.timezone-edit {
  padding: 0px;
}

.admin-txt-info {
  font-size: 13px;
  margin-bottom: 20px;
  color: gray;
}

/*--------------------------------------------------------------------------------*/
/*-------------- DepartmentDetail DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.dept-edit-button {
  color: $green;
  margin-left: 30%;
  font-size: 16px;
}

.dept-delete-button {
  color: $red;
  margin-left: 10px;
  font-size: 16px;
}

.dept-add {
  display: inline-block;
  color: $blue;
  margin-bottom: 20px;
  cursor: pointer;
}

.dept-close {
  margin-left: 95%;
  margin-top: 10px;
}

.dept-header {
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 10px 15px !important;
}

.ReactTable .rt-thead.-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  box-shadow: none !important;
}

.ReactTable .-pagination {
  box-shadow: none !important;
}

.ReactTable .-pagination .-btn {
  background: $blue !important;
  color: #fff !important;
}

.ReactTable .-pagination .-pageJump {
  margin-right: 20px;
}

/*--------------------------------------------------------------------------------*/
/*-------------- SearchBar DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/


.search-box .fa-times {
  position: absolute;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 100%;
  line-height: 2.375rem;
  text-align: center;
  margin-right: 5%;
  color: grey;
  margin-left: 550px;
}

/* .main-search-box{
  
  } */


/*--------------------------------------------------------------------------------*/
/*-------------- SearchBar DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/



.sticky-class {
  background-color: rgb(247, 247, 146);
  border: 0px;
  border-color: rgb(247, 247, 146);
  outline: none;
  width: 100%;
  outline-width: 0;
  outline-color: rgb(247, 247, 146);
  outline-offset: 0px;
  position: relative;
  /* cursor: auto; */
}

.sticky-header {
  background-color: rgb(247, 247, 146);
  padding: 25px 5px;
  border-bottom: 1px solid #00000024;
  min-height: initial;
}

.stickies {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(247, 247, 146);
  border: 2px;
  border-color: rgb(247, 247, 146);
  padding: 20px 25px;
  outline-width: 1px;
  outline-color: rgb(247, 247, 146);
  outline-offset: 0px;
}

hr {
  border-top: 1px solid #eee;
}

.react-draggable-dragging {
  background: #f1f5f8;
  opacity: .8
}



/*--------------------------------------------------------------------------------*/
/*-------------- ToDoList DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/


.todolist-panel {
  display: block;
  .MuiCardContent-root{
    padding: 0; 
    h5{
      font-size: 16px;
      font-weight: 500;
    }
    p{
      font-size: 13px;
    }
    &:last-child{
      padding-bottom: 15px;
    }
    .form-group{ 
      > div{
        box-shadow: none;
      }
    }
  }
}

.todolist-panel>div>div i {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba($black, .5);
  cursor: pointer;
}

.todolist-panel>div>div label {
  word-break: break-word;
  padding-right: 40px;
}

.todolist-panel>div>div i.fa-check {
  display: none;
}

.todolist-panel>div>div:hover i.fa-circle-thin {
  display: none;
}

.todolist-panel>div>div:hover i.fa-check {
  display: inline-flex;
  color: #2c69d1;
}

.model-right-bar .modal-dialog .modal-body {
  overflow-y: auto;
}


.todolist-panel>div>div {
  position: relative;
  padding-left: 0px;
  box-shadow: none;
  border-bottom: 1px solid $border-color;
}

/* .model-right-bar .modal-dialog{
   border: solid grey 1px;
  } */





/*--------------------------------------------------------------------------------*/
/*-------------- ViewProfile DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block
}

.display-none {
  display: none;
}

.imgEditProfile {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.avatarbtn {
  margin-top: 5px;
  margin-left: 15px;
}

.btn.disabled {
  cursor: unset;
}



/*--------------------------------------------------------------------------------*/
/*-------------- UnreadSentStickyList DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.unread-sticky-class {
  background-color: rgb(247, 247, 146);
}



/*--------------------------------------------------------------------------------*/
/*-------------- ContextMenuOption DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
.bg {
  font-family: sans-serif;
  background-color: wheat;
  height: 100px;
  padding: 1.5rem;

}

.menu {
  background-color: white;
  position: fixed;
  opacity: 0.1;
  z-index: 4;
  /* overflow-x:hidden; */
  /* padding: 10px; */
}


.menuItem {
  padding: 8px;
  margin: 3px 0px;
  border: none;
  cursor: pointer;
}

.menuItem:hover {
  background-color: rgb(41, 123, 245);
  color: white;
}


/*--------------------------------------------------------------------------------*/
/*-------------- KnockStickyCount DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.modal {
  background: rgba(0, 0, 0, 0.6);
}

.sticky-modal .modal-body {
  padding: 25px;
}

.sticky-modal .modal-body label {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
}

.btn-group-div button {
  min-width: 100px;
}

.sticky-body>div {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
}

.sticky-body>div ul {
  margin: 0;
  padding: 0;
  padding-left: 0;
  list-style: none;
}

.sticky-body>div ul li {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.sticky-body>div ul li::before {
  content: "";
  height: 5px;
  width: 5px;
  background: #aaa;
  border-radius: 50%;
  margin-right: 7px;
}

.sticky-body>div:last-child {
  margin-bottom: 0;
}

.sticky-body>div span {
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
  color: #888;
}

.sticky-body>div h4 {
  font-size: 18px;
}

.sticky-header,
.stickies {
  /* background-color: #fff; */
  /* border-color:#fff; */
  outline: none;
}

.sticky-header {
  padding: 15px;
  border-bottom: 1px solid #aaa;
  position: relative;
  z-index: 1;
}

.sticky-header .modal-title {
  font-size: 16px;
}

.sticky-header .modal-title small {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  margin: auto;
  text-align: center;
  z-index: 11;
  /* background: #eee; */
  background: rgb(247, 247, 146);
  max-width: 60%;
  font-size: 12px;
  border-radius: 30px;
}

.sticky-header .modal-title small i {
  margin: 0 3px;
}

.sticky-body {
  position: relative;
  z-index: 0;
}


/*--------------------------------------------------------------------------------*/
/*-------------- OfficeLayout DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.draggeable-div {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.draggeable-div:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.custom-save-button {
  background-color: #2c69d1;
  color: white;
  width: 55px;
  border-radius: 2px;
  border: 1px;
  margin: 2px;
}

.custom-edit-button {
  background-color: #2c69d1;
  color: white;
  width: 55px;
  border-radius: 2px;
  border: 1px;
  margin: 2px;
}

.custom-cancel-button {
  background-color: #6c757d;
  color: white;
  width: 65px;
  border-radius: 2px;
  border: 1px;
  margin: 2px;
}

.styles_Editext__buttons_container__1kphL {
  position: absolute;
  bottom: 0;
  right: -25px;
  top: 0;
  margin: 9px 0 !important;
  height: 30px;
  font-size: 13px;
  flex-direction: column;
}

.styles_Editext__buttons_container__1kphL button {
  width: 100%;
}

.styles_Editext__input__1534X {
  padding-right: 20px;
}





/*--------------------------------------------------------------------------------*/
/*-------------- StickyNotes DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
.sticky-class .form-control {
  background-color: rgb(247, 247, 146);
  border: 0px;
  border-color: rgb(247, 247, 146);
  outline: none;
  outline-width: 0;
  outline-color: rgb(247, 247, 146);
  outline-offset: 0px;
  /* cursor: auto; */
}

/* .sticky-class .form-control:focus{
    background-color: rgb(247, 247, 146);
    border: 0px;
    border-color: rgb(247, 247, 146);
    outline: none;
    outline-width: 0;
    outline-color: rgb(247, 247, 146);
    outline-offset: 0px;
    /* cursor: auto; 
  } */

textarea:focus {
  outline: 0;
  border: none;
}

.sticky-class {
  background-color: rgb(247, 247, 146);
}



/*--------------------------------------------------------------------------------*/
/*-------------- MultipleEmail DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.formControl {
  width: 350px;
  margin: 100px;
  padding: 80px;
}



/*--------------------------------------------------------------------------------*/
/*-------------- ContextMenuOption DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
/* .bg {
	font-family: sans-serif;
	background-color: wheat;
	height: 100px;
	padding:1.5rem;
	
} */

.menu {
  background-color: white;
  position: fixed;
  opacity: 0.1;
  z-index: 4;
  /* overflow-x:hidden; */
  /* padding: 10px; */

}

.menuItem {
  padding: 8px;
  margin: 3px 0px;
  border: none;
  cursor: pointer;
}

.menuItem:hover {
  background-color: rgb(41, 123, 245);
  color: white;
}




/*--------------------------------------------------------------------------------*/
/*-------------- KnockStickyCount DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/


.modal {
  background: rgba(0, 0, 0, 0.6);
}

.sticky-modal .modal-body {
  padding: 25px;
}

.sticky-modal .modal-body label {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
}

.btn-group-div button {
  min-width: 100px;
}

.sticky-body>div {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
}

.sticky-body>div ul {
  margin: 0;
  padding: 0;
  padding-left: 0;
  list-style: none;
}

.sticky-body>div ul li {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.sticky-body>div ul li::before {
  content: "";
  height: 5px;
  width: 5px;
  background: #aaa;
  border-radius: 50%;
  margin-right: 7px;
}

.sticky-body>div:last-child {
  margin-bottom: 0;
}

.sticky-body>div span {
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
  color: #888;
}

.sticky-body>div h4 {
  font-size: 18px;
}

.sticky-header,
.stickies {
  /* background-color: #fff; */
  /* border-color:#fff; */
  outline: none;
}

.sticky-header {
  padding: 15px;
  border-bottom: 1px solid #aaa;
  position: relative;
}

.sticky-header .modal-title {
  font-size: 16px;
}

.sticky-header .modal-title small {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  margin: auto;
  text-align: center;
  z-index: 11;
  /* background: #eee; */
  background: rgb(247, 247, 146);
  max-width: 60%;
  font-size: 12px;
  border-radius: 30px;
}

.sticky-header .modal-title small i {
  margin: 0 3px;
}



/*--------------------------------------------------------------------------------*/
/*-------------- MainOffice DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.office_layout .disable {
  pointer-events: none;
  opacity: 0.3;
}

.custom-save-button {
  // background-color: #2c69d1;
  // color: white;
  // width: 55px;
  // border-radius: 5px;
  // border: 1px;
  visibility: hidden
}

.custom-edit-button {
  // background-color: #2c69d1;
  // color: white;
  // width: 55px;
  // border-radius: 5px;
  // border: 1px;
  visibility: hidden;
}

.custom-cancel-button {
  // background-color: #6c757d;
  // color: white;
  // border-radius: 5px;
  // border: 1px;
  visibility: hidden
}

.ceo-room .ceo-desk .ceo-front-chair .desk-chr {
  display: inline-block;
  margin: 0 5px;

}

.notify-icon {
  position: absolute;
  margin: -25px;
  top: 50px;
  fill: red;
}

.notify {

  fill: red;
}



/*--------------------------------------------------------------------------------*/
/*-------------- StickyNotes DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
.sticky-class .form-control {
  background-color: rgb(247, 247, 146);
  border: 0px;
  border-color: rgb(247, 247, 146);
  outline: none;
  outline-width: 0;
  outline-color: rgb(247, 247, 146);
  outline-offset: 0px;
  /* cursor: auto; */
}

/* .sticky-class .form-control:focus{
    background-color: rgb(247, 247, 146);
    border: 0px;
    border-color: rgb(247, 247, 146);
    outline: none;
    outline-width: 0;
    outline-color: rgb(247, 247, 146);
    outline-offset: 0px;
    /* cursor: auto; 
  } */

textarea:focus {
  outline: 0;
  border: none;
}

.sticky-class {
  background-color: rgb(247, 247, 146);
  z-index: 0;
}




/*--------------------------------------------------------------------------------*/
/*-------------- policy DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
.card #content {
  padding: 25px;
  margin: 25px;
}

.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}



/*--------------------------------------------------------------------------------*/
/*-------------- errornotfound DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.error-template {
  padding: 40px 15px;
  text-align: center;
}

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.error-actions .btn {
  margin-right: 10px;
}

h2 {
  font-size: 40px;
}




/*--------------------------------------------------------------------------------*/
/*-------------- ContextMenuOption DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
  // position: absolute !important;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  line-height: 1.5;
  padding: 10px 20px;
  text-align: inherit;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 0;
  transition: all .15s ease-in-out;

  i {
    margin-right: 7px;
  }

  strong {
    font-weight: 500;
  }
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item:hover {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, .15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, .15);
}




/*--------------------------------------------------------------------------------*/
/*-------------- google_calendar DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/
.long-title h6 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 5px;
}


/*--------------------------------------------------------------------------------*/
/*-------------- Routers DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

/* .wrapper {
  height: 100vh;
  width: 100%;
} */

.form-control.error {
  border: 1px solid var(--maroune);
}

/*
   * Off Canvas
   * --------------------------------------------------
   */
@media screen and (max-width: 767px) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    transition: all .25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-right .sidebar-offcanvas {
    right: -50%;
    /* 6 columns */
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -50%;
    /* 6 columns */
  }

  .row-offcanvas-right.active {
    right: 50%;
    /* 6 columns */
  }

  .row-offcanvas-left.active {
    left: 50%;
    /* 6 columns */
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 50%;
    /* 6 columns */
  }
}

@media screen and (max-width: 500px) {


  .row-offcanvas-right .sidebar-offcanvas {
    right: -100%;
    /* 6 columns */
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -100%;
    /* 6 columns */
  }

  .row-offcanvas-right.active {
    right: 50%;
    /* 6 columns */
  }

  .row-offcanvas-left.active {
    left: 100%;
    /* 6 columns */
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 100%;
    /* 6 columns */
  }
}



/* 
.overlay-loader {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: #ffffffc2;
}

.loader-wrap {
  position: fixed;
  left: 47%;
  top: 35%;
  z-index: 9999;
  margin: 0 auto;
}

.loader{
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
} */

/* LOADER 1 */
/* 
#loader-1:before, #loader-1:after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent; 
  border-top-color: var(--blue);
}

#loader-1:before{
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after{
  border: 10px solid var(--gray);
}

.load-text{
color: black;
}

@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


 */












/*--------------------------------------------------------------------------------*/
/*-------------- LOADER DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/


.overlay-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
}


/* HM SPINNER */
.hm-spinner {
  height: 115px;
  width: 115px;
  border: 5px solid transparent;
  border-top-color: #2c69d1;
  border-bottom-color: #2c69d1;
  border-radius: 50%;
  margin: auto;
  position: relative;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.hm-spinner::before {
  content: "";
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border: 5px solid transparent;
  border-top-color: #0abcf9;
  border-bottom-color: #0abcf9;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;

}


@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.load-text {
  margin-top: 20px;
}








/*--------------------------------------------------------------------------------*/
/*-------------- TimeCalendar DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/


div.sticky {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 15px;
  z-index: 100;
  height: 60px;
  // height: 85px; 
  left: 0;
  background: $white;
  transition: all .15s ease-in-out;
  border-top: 1px solid $border-color;

  &.show-time-calendar {
    bottom: -110px;
  }
}

.react-date-picker {
  border: none;
}


.btn-group .button {
  color: black;
  text-align: center;
  text-decoration: none;
  // display: inline-block;
  font-size: 12px;
  cursor: pointer;
  float: left;
  padding: 15px 5px;
  border: none;
}

.btn-group {
  width: 100%;
}

/*   
.b1{
  background-color:  #f9b232;
}
.b2{
  background-color:  #f6b235;
}
.b3{
  background-color:  #eeaf39;
}
.b4{
  background-color:  #e9b03e;
}
.b5{
  background-color:   #e5b143;
}
.b6{
  background-color:   #e0b048;
}
.b7{
  background-color:   #d6af50;
}
.b8{
  background-color:   #ceae55;
}
.b9{
  background-color:   #c8ae5a;
}
.b10{
  background-color:   #c0ad62;
}
.b11{
  background-color:   #c0ad62;
}
.b12{
  background-color:   #a7aa76;
}
.b13{
  background-color:    #a0aa7d;
}
.b14{
  background-color:    #8aa282;
}
.b15{
  background-color:    #86a791;
}
.b16{
  background-color:    #7ca699;
}
.b17{
  background-color:    #72a5a1;
}
.b18{
  background-color:    #69a4a9;
}
.b19{
  background-color:    #5fa3af;
}
.b20{
  background-color:     #4594abe0;
}
.b21{
  background-color:     #4ea1bf;
}
.b22{
  background-color:     #3e9ec5;
}
.b23{
  background-color:     #3ea0cb;
}
.b24{
  background-color:     #369ed1;
} */

.btn-group .button:not(:last-child) {
  border-right: none;
  /* Prevent double borders */
}

.btn-group .button:hover {
  background-color: #dedabd;
}

.btn-group .button:onclick {
  background-color: #dedabd;
  border: black 1px;
}

.dt-picker {
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  .calenderDateBox {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .cal-img-date {
    padding: 9px;
    border-right: 1px solid $border-color;
  }

  .cal-img-date img {
    height: 40px;
    min-width: 40px;
    cursor: pointer;
  }

  .dt-img-home {
    margin-right: 10px;
  }

  .calenderSelection {
    flex: auto;
    display: flex;
    align-items: center;
    padding: 0 15px;
    min-height: 60px;

    .shortText {
      cursor: pointer;
    }
  }
}

.dt-picker>.row {
  padding: 2px 20px 5px;

  label {
    margin-bottom: 0;
  }
}

.img {
  vertical-align: middle;
  margin-top: 20px;
  width: 100%;
}

.dayTime {
  float: right;
}

.nightTime {
  float: left;
}

.shortText {
  font-size: 12px;
}

/* ----------- dt-dates-selection */
.dt-time-place {
  font-size: 10px;
  margin-top: auto;
  padding: 10px 3px;

  strong {
    display: block;
    margin: 2px 0 0;
    font-size: 17px;
    line-height: 20px;
  }

  hr {
    margin: 2px 0;
  }
}


.dt-dates-selection.btn-group {
  display: flex;
  background: rgb(249, 178, 50);
  background: linear-gradient(90deg, rgba(249, 178, 50, 1) 0%, rgba(54, 158, 209, 1) 100%);
}

.dt-dates-selection.btn-group button {
  padding: 7px 0;
  flex: 1;
  position: relative;
  z-index: 1;
  background: none;
  border: 0;
  outline: 0;
}

.dt-dates-selection.btn-group button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  height: 40px;
  width: 100%;
  background: rgba(255, 225, 255, .4);
  z-index: -1;
  opacity: 0;
  transition: all .15s ease-in-out;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  border-left: 2px dotted #000;
  border-right: 2px dotted #000;
  margin: -4px 0;
  border-radius: 3px;
}

.dt-dates-selection.btn-group button:hover::after,
.dt-dates-selection.btn-group button:focus::after {
  opacity: 1;
}

.dt-dates-selection.btn-group button:hover,
.dt-dates-selection.btn-group button:focus {
  background: none;
}


.dayNightBox {
  display: flex;
  align-items: center;
  flex: auto;
  padding: 0 10px;

  img {
    max-width: 25px;
  }
}

.calender-droup .input-group-prepend {
  position: relative;
}

.calender-droup .input-group-text {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;

  padding: 6px;
  background: no-repeat;
  border: 0;
}

.dt-day-img {
  margin-right: 10px;
}

.dt-night-img {
  margin-left: 10px;
}






/*--------------------------------------------------------------------------------*/
/*-------------- OFFICE CARD RESPONSIVE DESIGN ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/


@media screen and (max-width: $screen-size-md) {

  .user-cabin .card .status,
  .user-cabin .card .ot-time,
  .user-cabin .card .time-zone,
  .user-cabin .card .meeting-with {
    font-size: 10px;
  }

  .user-cabin .card .name-time {
    font-size: 12px;
  }

  .user-cabin .card .ot-time {
    margin: 5px 0;
  }

  .user-cabin .card .card-img svg {
    flex: 0 0 40%;
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .office-cabins {
    padding: 18px 10px 10px;
    // z-index: 2;
  }

  .dashboard-wrapper .content-head {
    min-height: initial;
    padding: 10px 20px 10px;
    // margin-bottom: 10px;
  }

  .dt-dates-selection.btn-group button {
    padding: 13px 0;
  }

  // .dt-time-place {
  //   font-size: 12px;
  //   // padding-top: 5px;
  // }

  .shortText {
    margin: 0;
  }
}


#popover-trigger-click-root-close {
  transform: none !important;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 20px;
  right: 0px !important;
  bottom: 0px !important;
  margin: auto !important;
  max-height: 350px;
  height: auto;
  max-width: 500px;
  width: 400px;
  z-index: 9999;
  overflow-y: auto;

  .arrow {
    display: none;
  }
}

// .react-draggable{
//   transform: none !important;
// }

.sticky-body-swipe {
  position: relative;
  z-index: 0;

  .swipeable-list-item {
    .swipeable-list-item__content {
      background-color: #f7f791;

      h5 {
        margin-bottom: 5px;
      }
    }

    .stickies {
      //display: flex;
      align-items: center;
      padding: 15px 10px;
      color: $white;
    }
  }
}



.switch_control {

  &.MuiFormControl-root {
    border-radius: 0;
    width: auto; 
    display: table;
    margin-left: 15px;
    border: 1px solid $border-color;
    font-size: 14px;
  }

  .Mui-focused {
    .MuiSelect-selectMenu {
      &::after {
        color: $primary;
      }
    }
  }

  .MuiSelect-selectMenu,
  .MuiSelect-selectMenu:focus {
    background: transparent;
  }

  .MuiOutlinedInput-input {
    padding: 5px 10px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
  }

  .MuiSelect-selectMenu {
    position: relative;
    min-height: initial;

    &::after {
      content: "\f51c";
      font-family: 'Font Awesome 5 Free';
      font-weight: bold;
      position: absolute;
      right: 15px;
      color: $gray-600 !important;
    }
  }

  .MuiFormLabel-root,
  .MuiInputBase-root {
    color: $gray-600;
  }
  .MuiFormLabel-root{
    display: none;
  }

  .MuiOutlinedInput-root {
    border-radius: 50px;
    min-width: 175px;
  }

  .PrivateNotchedOutline-root-1, .MuiInputBase-formControl > fieldset{
    display: none;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    width: 100%;
    max-width: 125px;
    color: $gray-600;
    padding-right: 45px;
    font-size: 14px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $gray-600;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $gray-600;
  }

  .MuiSelect-iconOutlined {
    display: none;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }

}

.workspace-title {
  font-size: 14px;
  color: $gray-600;
  font-weight: 400;
  margin-right: auto;
  display: flex;
  align-items: center;
  position: relative;

  span {
    color: black;
  }
}


.acc-signin-btn {
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &.disable {
    opacity: .5;
    pointer-events: none;
  }
}

.form-mr {
  .form-group {
    margin-bottom: 10px;
  }
}


// .react-contextmenu.react-contextmenu--visible {
//   position: absolute !important;
// }

.more-channels-btn {
  padding: 10px;
  text-align: center;
  font-weight: 700;
}

.office-list {
  .styles_Editext__buttons_container__1kphL {
    right: 5px;
  }

  .styles_Editext__input__1534X {
    width: 85%;
    padding: 0px 5px;
    height: 21px;
    font-size: 12px;
  }

  &:hover {
    .styles_Editext__buttons_container__1kphL {
      visibility: visible;
    }
  }

  .btn-primary {
    i {
      margin-right: 5px;
      width: 17px;
    }
  }
}

.left-sidebar .user-list-panel ul li .user-list li a {
  word-break: break-all;
}

.title-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;

  .member-count {
    background: $border-color;
    color: $black;
    font-size: 12px;
    font-weight: 500;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: -10px;
    z-index: 1;
  }

  input {
    padding: 3px;
    outline: none;
    height: 21px;
    width: 150px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

}

.jira-task-popup .ReactTable {
  width: 100%;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 0 1;
}

.ReactTable .-pagination .-pageJump input {
  width: 50px;
}

.ReactTable .-pagination .-pageInfo,
.ReactTable .-pagination .-pageSizeOptions {
  margin: 0;
}

.css-df17o1 {
  background: rgba($white, .7) !important;
  color: #888 !important;
}

.more-channels-btn i {
  margin-right: 10px;
}

.css-50etie svg circle {
  stroke: #888 !important;
}

.pwd-active i {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.sticky.card {
  padding-left: 0;
  transition: all .15s ease-in-out;
}

.toggle.dashboard-wrapper .sticky.card {
  margin-left: 225px;
  transition: all .15s ease-in-out;
}

// .sc-htpNat > div{
//   margin: 0 !important; 
// }

.Toastify__toast {
  min-height: initial;
  padding: 10px 15px;
  display: flex;
  align-items: center;

  .Toastify__close-button {
    align-self: auto;
    opacity: 1;
  }
}

.Toastify__toast--success {
  background: rgb(0, 200, 117);
}


.ReactTable .rt-th,
.ReactTable .rt-td {
  display: flex;
  align-items: center;
}

.msg-notify {
  position: initial !important;
  min-width: 8px !important;
  padding: 3px 5px !important;
  font-size: 11px !important;
  background-color: $white !important;
  color: $black !important; 
  margin-right: 5px !important;
}
.css-2b097c-container{
  z-index: initial;
}
.css-1pahdxg-control{
   z-index: 0;
 } 
.css-26l3qy-menu{
  z-index: 100 !important; 
  background: $white !important;
}

/*--------------------------------------------------------------------------------*/
/*-------------- email-tem-content ------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------*/

.email-tem-content{
  .card{
    max-width: 550px;
    padding: 30px;
    margin-right: 150px;
  }
  .logo-wrap{
    background: #b8e3fd;
    padding:100px 30px 200px;
    margin-bottom: -100px;
    text-align: center;
    img{
      margin: auto;
    }
  }
  .btn{
    padding: 10px;
    width: auto;
    display: inline-flex;
    max-width: 150px;
  }
}

// MuiButtonGroup Customization

.MuiButtonGroup-root{
  input{
    max-width: 35px;
    border: 0;
    border: 1px solid $secondary;
    text-align: center;
  }
}