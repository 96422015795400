@import "../node_modules/react-toastify/dist/ReactToastify.css"; 
@import "../src/assets/scss/bootstrap";


// @import "../src/var.css";

html {
  position: relative;
  min-height: 100%;
}
