.right-sidebar {
    min-width: 70px;
    max-width: 70px;
    background: $white;
    margin-left: auto;
    text-align: center;
    position: relative;
    border-left: 1px solid $border-color;
    z-index: 12;
    display: flex;
    flex-direction: column;

    .action-bar {
        display: flex;
        border-bottom: 1px solid $border-color;
        align-items: center;
        justify-content: center;
        min-height: 50px;
    }

    .actions-list {
        max-height: calc(100% - 80px - 80px);
        overflow-y: auto;

        li {

            border-bottom: 1px solid $border-color;
            &:last-child{
                border-bottom:0;
            }

            a {
                display: block;
                padding: 10px 5px 10px;
                color: $gray-600; 
                transition: all .15s ease-in-out;
                cursor: pointer;

                @media screen and (max-width: $screen-size-md) {
                    padding: 10px 15px 0;
                }

                img, i {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    // background: $border-color; 
                    font-size: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    font-weight: 900;
                    position: relative;
                }

                &:hover{
                    color: $blue; 
                }                

                span {
                    display: block;
                    margin: 2px auto;
                }

                &#notification{
                    div{
                        position: absolute !important;
                        top: 0;
                        right: 0;
                        span{
                            background-color: $blue-cream !important;
                        }
                    }
                }

            }
        }
    }


    .door-status {
        border-bottom: 1px solid $border-color;

        .door-action {
            position: inherit;
            height: auto;
            width: 100%;
            right: 0;
            bottom: 0;
            margin: auto;
            padding:10px 5px;
            // border: 2px solid $white;
            // border-radius: 50%;
            // background: $gray-500;
            // color: $white;
            font-size: 10px;
            line-height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            z-index: 13;

            .door-img {
                height: 30px;
                width: 50px;
                background: url("#{$bgimage-path}p-door-open.png") no-repeat center;
                background-size: 30px;
                margin-bottom: 5px;
            }

            // &::before{
            //     content: "Door";
            //     position: absolute;
            //     left: 0;
            //     right: 0;
            //     top: -100%;
            //     height: 50%;
            //     background: $blue-cream;
            //     transition: all 0.5s ease-in-out;
            //     display: flex;
            //     align-items: flex-end;
            //     justify-content: center;

            // }
            // &::after{
            //     content: "Open";
            //     position: absolute;
            //     left: 0;
            //     right: 0;
            //     bottom: -100%;
            //     height: 50%;
            //     background: $blue-cream;
            //     transition: all 0.5s ease-in-out;
            //     display: flex;
            //     align-items: flex-start;
            //     justify-content: center;

            // }

            &.d-closed {
                .door-img {
                    background: url("#{$bgimage-path}p-door-closed.png") no-repeat center;
                    background-size: 25px;
                }

                // right: 0;
                // .door{ 
                //     transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-105deg);
                // }
                // &::before{ 
                //     top: 0;             
                // }
                // &::after{ 
                //     bottom: 0;             
                // }
            }


        }
    }



}



.modal-door-status {
    &.door-action {
        position: initial;
        display: flex;
        align-items: center;
        width: auto;
        flex-direction: row;
        height: auto;
        padding: 0;

        .door-img {
            min-width: 50px; 
            height: 45px;
            width: 50px;
            background: url("#{$bgimage-path}p-door-open.png") no-repeat center;
            background-size: 40px;
            margin-bottom: 0;
            margin-right: 10px;
        }

        &.d-closed {
            .door-img {
                background: url("#{$bgimage-path}p-door-closed.png") no-repeat center;
                background-size: 35px;
            } 
        }
    }
}

.task-add {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        min-height: initial;
        padding: 3px 10px;
    }
}


.user-status {
    display: flex;
    margin: auto;
    background: $white;
    position: relative;
    align-items: center;

    .user-avtar {
        position: relative;
        border-radius: 50%;
        // height: 50px;

        // margin-right: 10px;
        img,
        svg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid rgba($black, 0.20);
            background: $white;
            box-shadow: 0 0 10px rgba($black, 0.05);
        }

    }

    .user-name {
        line-height: 16px;

        strong {
            font-size: 16px;
            text-transform: capitalize;
        }

        p {
            // color: rgba($white, .80);
            font-size: 12px;
            margin-top: 3px;
        }
    }
}


.status-change {

    position: absolute;
    right: 10px;
    bottom: 5px;
    height: 17px;
    width: 17px;

    > a, .active-status {
        color: $green; 
        height: 17px;
        width: 17px ; 
        // background: $green;
        // border-radius: 50%;
        right: -2px;
        bottom: -2px;
        @include flexAlignCenter;
        font-size: 10px;
    }
    
    .show { 
        background: $white;
        height: 16px;
        width: 17px ; 
        border: 1px solid;
        border-radius: 50%;  
    }

    .status-available {
        color: $green !important;
    }

    .status-busy {
        color: $orange !important;
    }

    .status-disturb {
        color: $red !important;
    }

    .status-break {
        color: $room-border !important;
    }

    .status-meeting {
        color: $purple !important;
    }

    .status-other {
        color: $cyan !important;
    }

    .status-list {
        position: absolute;
        right: 0;
        top: 100%;
        background: $white;
        box-shadow: 0 3px 5px rgba($black, .25);
        min-width: 150px;
        text-align: left;
        display: none;
        z-index: 1001;

        a {
            padding: 5px 10px;
            display: block;
            color: $gray-700;

            i {
                margin-right: 5px;
                width: 20px;
                text-align: center;
            }

            &:hover,
            &:focus {
                background: lighter($blue-card,.5);
            }
        }

    }

    &:hover {
        .status-list {
            display: block;
        }
    }

}