.dashboard-wrapper .content {
    padding: 0;
}

.toggle {
    &.dashboard-wrapper {
        .content {
            margin-left: 225px;
            transition: all .15s ease-in-out;
        }

        @media screen and (max-width: $screen-size-md) {
            .modal.minimizeChat {
                width: 290px;

                .chat-area-main {
                    padding: 40px 10px 20px;
                }

                .chat-area-main .chat-msg-content {
                    margin-left: 5px;
                }

                .chat-area-main .owner .chat-msg-content {
                    margin-left: 0;
                    margin-right: 5px;
                }

                .chat-msg-date {
                    left: calc(100% + 5px);
                }

                .chat-area-main .owner .chat-msg-date {
                    left: auto;
                    right: calc(100% + 5px);
                }

                .chat-msg-date span {
                    max-width: 60px;
                }
            }

            // .user-cabin .card {
            //     max-width: 145px;
            // }
        }
    }

    +.model-left-bar {
        left: 225px;
    }
}



.o-auto-content {
    display: flex;
    flex-wrap: wrap;
    // margin-left: -15px;
    // margin-right: -15px;
    // > div{ 
    //   flex: initial !important;
    //   max-width: initial !important;
    //   width:auto ;
    //   &.col-xl-3{
    //     flex: initial !important;
    //     max-width: initial !important;
    //     width: 33.33%;
    //   }
    // } 

    >div {
        padding: 0 15px;
    }
}

.user-cabin {
    .slide {
        padding-right: 25px !important;
        position: relative;
    }

    .card {
        // min-width: 170px;
        max-width: 170px;
        height: auto;
        background: $white;
        border: 2px solid $yellow;
        border-radius: 5px;
        position: relative;

        @media screen and (max-width: $screen-size-md) {
            max-width: 168px;
        }



        &::before {
            content: "";
            background: none;
            position: absolute;
            right: -2px;
            bottom: -2px;
            height: 33px;
            width: 5px;
            background: $room-border;
            z-index: 1;

            @media screen and (max-width: $screen-size-md) {
                height: 30px;
            }
        }


        .name-time {
            display: block;
            padding: 5px;
            background: $yellow;
            text-align: center;
            position: relative;
            text-transform: capitalize;
            font-size: 12px;
            // &::before {
            // 	content: ""; 
            // 	position: absolute;
            // 	bottom: -11px;   
            // 	height: 43px;
            // 	width: 14px; 
            // 	background: url("#{$bgimage-path}d-closed.png") no-repeat;
            // 	background-size: 14px;
            // 	right: -2px;
            // 	@media screen and (max-width: $screen-size-md) {
            // 		height: 39px;					
            // 		background-size: 12px;
            // 		right: -3px; 
            // 	}
            // } 
        }

        &.my-cabin {
            border-color: $green !important;
            box-shadow: 5px 0 20px rgba($blue, 0.3) !important;

            .name-time {
                background: $green !important;
                color: $white !important;
            }

            .status {
                background: $green !important;
                color: $white !important;
            }
        }

        &.card-door-open {
            border: 2px solid $blue-card;

            .name-time {
                background: $blue-card;
                // &::before {
                // 	background: url("#{$bgimage-path}d-open.png") no-repeat;
                // 	background-size: auto;
                // 	height: 43px;
                // 	width: 14px;
                // 	bottom: -12px;
                // 	right: -2px; 
                // }
            }

            .status {
                background: $blue-card;
            }
        }

        &.out-office {
            border-color: $out-office;
            opacity: .6;

            &::before {
                right: -1px !important;
                bottom: 0 !important;
                height: 30px !important;
                width: 5px !important;
                background: $room-border !important;
                z-index: 1;
                transform: none !important;
            }

            .name-time,
            .status {
                background: $out-office;

                &::before {
                    display: none;
                }
            }
        }

        &.my-cabin-dept {
            border-color: #4bb1ef;

            .name-time,
            .status {
                background: #4bb1ef;
            }
        }

        &.avail-office {
            border-color: $avail-office;

            .name-time,
            .status {
                background: $avail-office;

                &::before {
                    display: none;
                }
            }
        }

        &.card-door-open::before {
            content: "";
            background: url("#{$bgimage-path}user-card-door.png") no-repeat;
            position: absolute;
            right: -42px;
            bottom: -17px;
            height: 60px;
            width: 54px;
            transform: scale(0.5);
        }

        .status {
            margin: 0 auto;
            padding: 2px 10px;
            font-size: 11px;
            background: $yellow;
            border-radius: 0 0 5px 5px;
            min-height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 40px;
        }

        .ot-time {
            display: block;
            text-align: center;
            font-size: 12px;
            margin: 5px 0 0;
        }

        .meeting-with {
            display: block;
            text-align: center;
            font-size: 11px;
            border-top: 1px solid $white;
            padding-top: 2px;
            // display: none;
        }

        &.card-admin {

            // border-color: rgba($green, 0.4);
            // .name-time,
            // .status {
            // 	background: rgba($green, 0.4); 
            // 	&::before {
            // 		display: none;
            // 	}
            // }			
            .card-img {
                &::before {
                    content: "\f2bd";
                    color: #333;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 40px;
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    padding-left: 10%;
                }
            }
        }

        .card-img {
            display: flex;
            position: relative;

            svg {
                height: auto;
                width: auto;
                flex: 0 0 50%;
                max-width: 50%;
            }

            .user-desk-img {
                margin-top: auto;
                padding-left: 5px;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        .time-zone {
            position: relative;
            height: 26px;
            width: 60px;
            display: flex;
            font-size: 12px;
            align-items: center;
            padding-left: 3px;
            padding-top: 3px;
            color: $second-dark;
            margin-bottom: -25px;
            margin-left: 10px;

            img {
                position: absolute;
                left: 0;
                top: 0;
            }

            &::before {
                content: "";
                min-height: 20px;
                min-width: 20px;
                display: block;
                transform: scale(0.7);
            }

            &.night-time::before {
                background: url("#{$bgimage-path}night-time.png") no-repeat;
            }

            &.day-time::before {
                background: url("#{$bgimage-path}day-time.png") no-repeat;
            }
        }

        &:hover {
            box-shadow: 0 0 15px rgba($blue, .3);
        }
    }
}

.dFFQiB,
.w_slide_next {
    right: -20px !important;
}

.kaHnvc,
.w_slide_prev {
    left: -20px !important;
}

.geRExE button,
.w_slide {
    background: $white;
    color: $blue-cream;
    border: none;
    font-size: 20px;
    height: 40px;
    width: 30px;
    min-height: 40px;
    min-width: 30px;
    outline: none;
    border-radius: 5px;
    border: 1px solid $border-color;

    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;

    @media screen and (max-width: $screen-size-md) {
        // box-shadow: 0 0 10px rgba($black, .1);
        height: 25px;
        width: 25px;
        min-height: 25px;
        min-width: 25px;
        font-size: 14px;
    }
}

.office-cabins .cabin-title {
    max-width: 260px;
}

.cabin-door-open {
    position: relative;
    background: #fff;
}

.cabin-door-open::before {
    content: "";
    position: absolute;
    background: url("#{$bgimage-path}door-open.png") no-repeat;
    right: -47px;
    bottom: -16px;
    height: 64px;
    width: 84px;
    transform: scale(0.5);
}


.office-cabins {
    position: relative;
    border: 1px solid $border-color;
    background: $white;
    margin-top: 25px;
    margin-bottom: 20px;
    min-width: 100px;
    padding: 20px 10px 10px;
    width: 100%;
    border-radius: 0;
    // z-index: 0;

    >div {
        padding: 0 10px !important;
    }

    // .cabin-title {
    // 	position: absolute;
    // 	z-index: 1;
    // 	top: -25px;
    // 	background: $white;
    // 	color: $blue;
    // 	font-weight: 600;
    // 	padding: 10px;
    // 	border-radius: 5px;
    // 	display: block;
    // 	display: -ms-inline-flexbox;
    // 	left: 0;
    // 	right: 0;
    // 	margin: auto;
    // 	max-width: 235px;
    // 	text-align: center;
    // 	border: 3px solid $border-color;
    // 	font-size: 17px;
    // }
    .edit-cabin-title>div,
    .cabin-main-head .cabin-title {
        padding: 5px 30px;
        font-size: 14px;
    }

    .edit-cabin-title>div,
    .cabin-main-head .cabin-title {
        position: initial;
        border: 1px solid #dce4ee;
        font-size: 14px;
        background: #fff;
        border-radius: 0;
        padding: 5px 30px;
        min-height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 150px;
        position: relative;
        color: #2c69d1;
        font-weight: 600;
        text-align: center;
        top: 0;
        max-width: initial;
        margin-bottom: 0;

        @media screen and (max-width: $screen-size-md) {
            padding: 5px 30px;
        }
    }

    .cabin-main-head {
        position: absolute;
        z-index: 1;
        top: -20px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;

        @media screen and (max-width: $screen-size-md) {
            top: -20px;
        }
    }

    .edit-cabin-title>div>div {
        margin: auto;
    }

    .user-card-list.user-cabin .slide {
        max-width: initial;
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding: 10px 0;
    }

    .gwZiig .cXYqcd {
        margin: 0 10px;
        max-width: initial;
        width: 100%;
    }

    .user-card-list {
        justify-content: center;
        max-width: 200px;
        min-width: 200px;
    }



}

.selected-cabin {
    display: flex;

    .office-cabins {
        width: auto;
        min-width: 230px;
    }
}

._loading_overlay_wrapper {
    z-index: 10;
}

// ._loading_overlay_wrapper .sc-htpNat>div {
//     width: auto !important;
// }



/*------------ OFFICE CABIN SET -------------------------------*/

.col-item-1 {
    max-width: 272px;
    flex: 0 0 272px;
}

.col-item-2 {
    max-width: 486px;
    flex: 0 0 486px;
}

.col-item-3 {
    max-width: 39%;
    flex: 0 0 39%;
}

.col-item-4 {
    max-width: 51%;
    flex: 0 0 51%;
}

.col-item-5 {
    max-width: 63%;
    flex: 0 0 63%;
}

.col-item-6 {
    max-width: 74%;
    flex: 0 0 74%;
}

.col-item-7 {
    max-width: 86%;
    flex: 0 0 86%;
}

.col-item-8 {
    max-width: 100%;
    flex: 0 0 100%;
}

.whrlgig_slider {
    justify-content: start !important;

    >div {
        min-width: 200px;
        max-width: 200px;
    }
}

// .virtual_wrapper {

//     .office-cabins {
//         // background: url("#{$bgimage-path}office-bg.png") $white repeat;
//         display: inline-flex;
//     }

//     .virtual_room {
//         max-width: initial;
//         min-width: initial;
//         padding: 0 25px !important;

//         .virtual_users {
//             display: flex;
//             justify-content: space-around;
//             align-items: end;
//             flex-direction: initial;
//             flex-wrap: wrap;
//             min-height: 150px;
//             border: 1px solid $border-color;

//             img {
//                 max-width: 80px;
//             }

//             svg {
//                 max-height: 40px;
//                 max-width: 40px;
//             }

//             >div {
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 padding-top: 10px;
//                 border: 1px solid $border-color;
//                 min-width: 82.5px;
//                 min-height: 43px;
//                 background-color: rgba($blue, .1);

//                 &:last-child {
//                     margin-top: 80px;
//                 }
//             }

//         }
//     }

//     .vr_user_2 {
//         min-width: 84px;
//         max-width: 84px;
//         background: url("#{$bgimage-path}vr-desk-2.jpg") no-repeat center;
//         background-size: 84px;
//     }

//     .vr_user_3 {
//         min-width: 168px;
//         max-width: 168px;
//         background: url("#{$bgimage-path}vr-desk-3.jpg") no-repeat center;
//         background-size: 168px;
//     }

//     .vr_user_4 {
//         min-width: 168px;
//         max-width: 168px;
//         background: url("#{$bgimage-path}vr-desk-3.jpg") no-repeat center;
//         background-size: 168px;
//     }

//     .vr_user_5 {
//         min-width: 250px;
//         max-width: 250px;
//         background: url("#{$bgimage-path}vr-desk-3.jpg") no-repeat center;
//         background-size: 168px;
//     }

//     .vr_user_6 {
//         min-width: 250px;
//         max-width: 250px;
//         background: url("#{$bgimage-path}vr-desk-3.jpg") no-repeat center;
//         background-size: 168px;
//     }

//     .vr_user_more {
//         min-width: 332px;
//         max-width: initial;
//         background: url("#{$bgimage-path}vr-desk-3.jpg") no-repeat center;
//         background-size: 168px;
//     }

// }


// VR ROOMS DESIGn



.virtual_wrapper {

    .office-cabins {
        // background: url("#{$bgimage-path}office-bg.png") $white repeat;
        padding-bottom: 20px;
    }

    .virtual_room {
        display: flex;
        .user-card-list{
            max-width: initial;
            min-width: initial;
            margin-right: 35px;
            position: relative;
            &:last-child{
                margin-right: 0;
            }
        }
        .vr_close{
            cursor: pointer;
            position: absolute;
            right: 0px;
            top: 0px;
            z-index: 1;
            height: 15px;
            width: 15px;
            display: flex;
            align-items: center;
            justify-content: center; 
            color: $gray-600;      
            transition: all .15s ease-in-out;
            transform: scale(1);
            &:hover{
                transform: scale(1.5);
            }
        }
    }

    .virtual_users {
        border-radius: 0;
        border: 4px solid rgba($black, .15);

        &:hover {
            box-shadow: none;
        }

        &::before {
            right: -5px;
            bottom: 0;
            width: 5px;
        }

        &.card-door-open {
            border: 4px solid rgba($black, .15);
        }
    }

    .common-desk-room {
        padding: 10px 0;
        border: 7px solid rgba($black, .1); 

        .desk-tbl {
            width: 100%;
            max-height: 55px;
        }

        .desk-row {

            >div {
                margin: 0 11px;
                text-align: center;
            }

            &.desk-row-up{
                img{
                    position: relative;
                    top: 7px;
                    transform: rotateX(180deg);
                }
            }

            &.desk-down {                
                svg {
                    transform: rotateX(180deg);
                }
                img {
                    transform: rotateX(0deg);
                }
            }

            img,
            svg {
                width: 30px;
                height: 30px;
                display: block;
                margin: auto;
            }
            img {
                width: 23px;
                height: 23px;
                display: block;
                margin: auto;
            }

            .avt-opacity{
                opacity: 1 !important;
            }
            justify-content: center;
        }
    }

    .user-card-list:nth-child(odd) {  
        .common-desk-room {
            background-color: rgba($blue-cream, .3);
        }
    }

    .user-card-list:nth-child(even) {    
        .common-desk-room {
            background-color: #dbd3c4;
        }
    }
    
    .user-card-list:nth-child(3n+3) { 
        .common-desk-room {
            background-color: rgba(99,190,139, .3);
        }
    }

    .room-things .desk-chr {
        height: 25px;
        width: 25px;
    }

    .vr_user_md{ 
        .virtual_users{
            max-width: 270px;
        }
    }

}
  

.user-cabin .card.my-cabin-dept{
    border-color: #6af3b2;
    .status,
    .name-time{
        background-color: #6af3b2;

    }
}
  
  .user-cabin .card.my-cabin-dept{ 
      -webkit-animation: flash linear 1s infinite;
      animation: flash linear 2s infinite;
  }
  @-webkit-keyframes flash {
      0% { opacity: 1; } 
      50% { opacity: .5; } 
      100% { opacity: 1; }
  }
  @keyframes flash {
      0% { opacity: 1; } 
      50% { opacity: .5; } 
      100% { opacity: 1; }
  } 
  /* Pulse class and keyframe animation */
//   .pulseit{
//       -webkit-animation: pulse linear .5s infinite;
//       animation: pulse linear .5s infinite;
//   }
//   @-webkit-keyframes pulse {
//       0% { width:200px; } 
//       50% { width:340px; } 
//       100% { width:200px; }
//   }
//   @keyframes pulse {
//       0% { width:200px; } 
//       50% { width:340px; } 
//       100% { width:200px; }
//   }