@media (max-width: 1440px) {
  .to_do_modal.model-right-bar {
    width: 60%;
  }

  .jira-task-popup .ReactTable {
    min-height: 500px;
  }
}

@media (max-width: 1280px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {}

@media (max-width: 375.98px) {}