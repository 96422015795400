.login-panel {
  display: flex;
  // justify-content: center;
  // flex-direction:column; 
  height: 100%;
  background: url("#{$bgimage-path}login-bg.png") no-repeat center left 17%;
  background-size: 50%;
  background-attachment: fixed;
  position: relative;
  // background-color: $blue-cream;
  // background-image: linear-gradient(315deg, $blue-cream 0%, $blue 74%);

  @media screen and (max-width: $screen-size-lg) {
    background-size: 50%;
    height: auto;
  }

  .login-content {

    width: 390px;
    margin: auto;
    margin-right: 17%;
    padding: 50px 0;
    position: relative;
    z-index: 10;

    @media screen and (max-width: $screen-size-lg) {
      margin-right: 10%;
    }

    @include media-breakpoint-down(lg) {
      padding: 30px 0;
    }

    @include media-breakpoint-down(sm) {
      width: 300px;
    }

    >img {
      display: block;
      margin: auto;
      margin-bottom: 28px;
      width: auto;
    }

    .card {
      padding: 40px;
      // background: transparent;
      // border: 0;
      box-shadow: 0 5px 25px rgba($black, .15);

      @include media-breakpoint-down(sm) {
        padding: 20px 25px;
      }

      .title {
        margin-bottom: 28px;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }

      .check-forgot-div {
        display: flex;

        a {
          margin-left: auto;
          color: $dark;
        }
      }

      .btn {
        width: 100%;
      }

      .have-acc {
        color: $dark;
        display: block;
        margin-top: 20px;
        text-align: center;
      }

    }

    .policy-link {
      margin-top: 21px;
      text-align: center;

      span {
        display: block;
      }
    }

  }

}

.pow-footer {
  text-align: center;
  padding: 0 0 50px;
}


.bg-animate {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;

  .bg {
    animation: slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #e2edff 50%, #fff 50%);
    bottom: 0;
    left: -50%;
    opacity: .5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
    height: auto;
  }

  .bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 4s;
  }

  .bg3 {
    animation-duration: 5s;
  }

  @keyframes slide {
    0% {
      transform: translateX(-25%);
    }

    100% {
      transform: translateX(25%);
    }
  }

}

.acc-signin-btn{
  display: flex;
  justify-content: center;
  a{
    margin-top: 15px;
  }
}